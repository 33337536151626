<template>
    <div class="apiAuthorization">
		<!-- 设置API -->
		<common-head url="/newHome" :title="$t('tba.APIAuthorization')" :is-return="true" @onclickRight="onclickRight">
			<span class="text" slot="text">{{ $t('tba.helpGuide') }}</span>
		</common-head>
		
        <div class="apiList bgWhite">
            <ul>
                <li @click="btnChange({id: 1, name: ' HUOBI '})">
                    <div class="spot"><img src="@/assets/imgs/icon/menu-spot.png"></div>
                    <img class="img-icon" src="@/assets/imgs/icon/api-huobi.png">
                    <span>HUOBI</span>
                </li>
                <li @click="btnChange({id: 2, name: ' BINANCE '})">
                    <div class="spot"><img src="@/assets/imgs/icon/menu-spot.png"></div>
                    <img class="img-icon" src="@/assets/imgs/icon/api-bian.png">
                    <span>BINANCE</span>
                </li>
				<li @click="btnChange({id: 3, name: ' OKEx '})">
				    <div class="spot"><img src="@/assets/imgs/icon/menu-spot.png"></div>
				    <img class="img-icon" src="@/assets/imgs/icon/api-okex.png">
				    <span>okex(V5)</span>
				</li>
            </ul>
        </div>
		
        <van-action-sheet
            v-model="show"
            :actions="actions"
            :cancel-text="$t('common.cancel')"
            close-on-click-action
            @select="onSelect"
        />
    </div>
</template>

<script>
	import commonHead from '@/components/commonHead';
    export default {
		name: 'apiAuthorization',
        components: {
			commonHead,
        },
        data() {
            return {
                show: false,
                apiDataList: [],
                exchange_id: "",
                exchange_name: "",
                actions: [
                    {name:  ""},
                    {name: ""},
                ],
            }
        },
        created() {
			this.actions[0].name = this.$t('tba.SetAPI');
			this.actions[1].name = this.$t('tba.DeleteAPI');
            this.getAPIDataList();
        },
        methods: {
			onclickRight() {
					  this.$router.push('/consultingService')
			},
            getAPIDataList() {
                this.$post2("Trade/Api/Index/getApiSet").then((res) => {
                    this.apiDataList = res.list;
                });
            },
            btnChange(item) {
                this.exchange_id = item.id;
                this.exchange_name = item.name;
                this.show = true;
            },
            onSelect(item) {
                this.show = false;
                if (item.name == this.$t('tba.DeleteAPI')) {
                    this.$dialog.confirm({
                        title: this.$t('tba.Delete'),
                        message: this.$t('tba.sure_to_delete'),
                    }).then(() => {
                        this.$post2("Trade/Api/Index/delApiKey", {
                            exchange_id: this.exchange_id,
                        }).then(() => {
                            this.$toast({message: this.$t('tba.delete_ok'),}
                            );
                        });
                    }).catch(() => {
                        this.$toast({message: this.$t('tba.delete_no'),});
                    });
                } else if (item.name == this.$t('tba.SetAPI')) {
                    this.$router.push({
                        path: "/viewAPI2",
                        query: {
                            exchange_id: this.exchange_id,
                            exchange_name: this.exchange_name,
                        },
                    });
                }
            },
        }
    };
</script>

<style lang="less" scoped>
    .apiAuthorization {
        .apiList {
            padding: 0 0.41rem;

            li {
                padding: 0.29rem 0;
                height: 1.19rem;
                border-bottom: 1px solid #F1F1F1;
                overflow: hidden;

                .img-icon {
                    height: 0.61rem;
                    margin-right: 0.22rem;
                    vertical-align: -0.2rem;
                }

                .spot {
                    float: right;

                    img {
                        width: 0.36rem;
                    }
                }

                span {
                    font-size: 0.25rem;
                    color: #02172E;
                }
            }

            li:last-child {
                border-bottom: none;
            }
        }
    }
</style>
